*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans Display", sans-serif;
  box-sizing: border-box;
}

:root {
  --primary-color: rgb(219, 82, 77);
  --secondary-color: rgb(70, 142, 145);
  --tertiary-color: rgb(67, 126, 168);
}

button {
  font-family: "Noto Sans Display", sans-serif;
  cursor: pointer;
  border: none;
  box-shadow: none;
  border-radius: 0.25rem;
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
