.modal {
  max-width: 30rem;
}

.container {
  padding: 1.25rem;
}

.title {
  font-size: 1.125rem;
  color: rgb(187, 187, 187);
  font-weight: 600;
  margin-bottom: 1rem;
}

.input {
  margin-top: 0.375rem;
}

.button {
  margin-top: 1.125rem;
  width: 100%;
}
